import React, { Component, PureComponent } from 'react';

import { Greetings, SignIn, ForgotPassword, RequireNewPassword } from 'aws-amplify-react';

import { Auth, Hub } from 'aws-amplify';

import ReactModal from 'react-modal';

import './FlatAuth.css';
/*
* @brief A component that handles initial user sign-in (takes username/password)
*/
class FlatSignIn extends SignIn {
  constructor(props) {
      super(props);
      Hub.listen('auth', this, 'FlatSignInListener');
      this.state.errorMessage = null;
  }

  onHubCapsule(capsule) {
    const { channel, payload } = capsule;
    if (channel === 'auth') { this.onAuthEvent(payload); }
  }

  /*
  * @brief Get notified of auth events, like invalid username/password
  */
  onAuthEvent(payload) {
    const { event, data } = payload;
    /* Example SignIn error messages:
    * - 'User does not exist.'
    * - 'Incorrect username or password.'
    */
    switch (event) {
        case 'signIn_failure':
            this.setState({errorMessage: data.message});
            break;
        default:
            this.setState({errorMessage: null});
            break;
    }
    //Try to hook the Auth event loop to notify when the state changes
    try {
        if(this.props.onEvent){this.props.onEvent(payload)}
    } catch (error) {}
  }

  showComponent(theme) {
    const error = this.state.errorMessage;
    const forgotPassClick = (e) => {
        this.setState({errorMessage: null});
        this.changeState('forgotPassword');
        e.preventDefault();
    };

    let titleText = "Sign In";
    if(this.props.wasForced && this.props.wasForced==='idle'){
        titleText = "Login time out; please sign in";
    }
    if(this.props.wasForced && this.props.wasForced==='blocked'){
        titleText = "Account locked, please contact a Manager or EDGE3 support.";
    }

    return (
        <FlatForm id="sign-in" title={titleText}>
            {error && <FlatErrorMessage>{error}</FlatErrorMessage>}
            <FlatInput onChange={this.handleInputChange} placeholder="Username" name="username" error={error} id='username-field' />
            <FlatInput onChange={this.handleInputChange} placeholder="Password" type="password" name="password" error={error} id='password-field'/>
            <div className="row m-t-25 text-left">
                <div className="col-sm-6 col-xs-12">
                </div>
                <div className="col-sm-6 col-xs-12 forgot-phone text-right">
                    <a href="#" onClick={forgotPassClick} className="text-right f-w-600 text-inverse" id='login-button'> Forgot Password?</a>
                </div>
            </div>
            
            <FlatButton onClick={this.signIn} text="Sign In" isSubmit />
           
        </FlatForm>
        
    );                          // sign in button isSubmit to allow password saving,
                                // otherwise Firefox does not offer to save the password
  }
}

/*
* @brief A component that displays a styled error message
*/
class FlatErrorMessage extends Component {
    render() {
        return (
            <div class="error-message-div form-control-danger">{this.props.children}</div>
        );
    }
}

/*
* @brief A component that displays when the user is logged in and has a logout button
*/
class FlatGreetings extends Greetings {
    constructor(props) {
        super(props);
        this.triggerSignOut = this.triggerSignOut.bind(this);
        this.props.registerSignOut(this.triggerSignOut);
    }
    triggerSignOut() {
        this.signOut();
    }
    render() {
        return (
          <div style={{display: "none"}}> </div>
        );
    }
}

/*
* @brief A component that displays a styled <input> element
*/
class FlatInput extends Component {
    render() {
        // if the 'error' prop is truthy, set an extra CSS class from the theme (makes red outline)
        const classes = this.props.error ? "form-control form-control-danger" : "form-control";
        const placeholder = this.props.placeholder || "";
        const name = this.props.name;
        const type = this.props.type || "text";
        const onChange = this.props.onChange;
        return (
            <div className="input-group">
                <input onChange={onChange} className={classes} placeholder={placeholder}
                       type={type} key={name} name={name} />
                <span className="md-line"></span>
            </div>
        );
    }
}

/*
* @brief A component that displays a styled <button> element
*
* @param isSubmit Toggle if the button should be a submit button or not (can affect styling and browser behavior)
* @param text The button text
* @param onClick The function to run when the button is clicked
*/
class FlatButton extends Component {
    render() {
        const isSubmit = this.props.isSubmit;
        const text = this.props.text || "Submit";
        const onClick = this.props.onClick;
        return (
            <div className="row m-t-15">
                <div className="col-md-12">
                    <button onClick={onClick} type={isSubmit ? "submit" : "button"} className="btn btn-primary btn-md btn-block waves-effect text-center">{text}</button>
                </div>
            </div>
        );
    }
}

/*
* @brief A component that displays a styled form element, for login forms
*/
class FlatForm extends Component {
    render() {
        return (
        <div id={this.props.id} className="modal fade show" role="dialog" style={{display: "block", paddingTop: "90px"}}>
            <div className="modal-dialog">
                <div className="login-card card-block login-card-modal">
                    <form className="md-float-material" onSubmit={(e) => {e.preventDefault();}}>
                        <div className="auth-box">
                            <div className="row m-b-20">
                                <div className="col-md-12">
                                    <h3 className="text-center txt-primary">{this.props.title}</h3>
                                </div>
                            </div>
                            {this.props.children}
                        </div>
                    </form>
                </div>
            </div>
        </div>
        );
    };
}

/*
* @brief A component that allows a user to reset a forgotten password via email
*/
class FlatForgotPassword extends ForgotPassword {
    constructor(props) {
        super(props);
        this.mySend = this.mySend.bind(this);
        this.mySubmit = this.mySubmit.bind(this);
        this.state.errorMessage = null;
    }
    /*
    * @brief The inner view we get when we need to input a username to reset the password for
    */
    sendView() {
        const error = this.state.errorMessage;
        return (
            [
            <FlatInput onChange={this.handleInputChange} placeholder="Username" name="username" error={error} />,
            <FlatButton onClick={this.mySend} text="Email Me a Login Code" />
            ]
        );
    }
    /*
    * @brief The inner view we get when we need to input a verification code (from email) and new password
    */
    submitView() {
        const error = this.state.errorMessage;
        return (
            [
            <FlatInput onChange={this.handleInputChange} placeholder="Verification Code" name="code" error={error} />,
            <FlatInput onChange={this.handleInputChange} placeholder="New Password" type="password" name="password" error={error} />,
            <p className="text-inverse text-right">Password must be at least 6 letters long.</p>,
            <FlatButton onClick={this.mySubmit} text="Change Password" />
            ]
        );
    }
    /*
    * @brief submit a request to get a verification code via email
    *
    * Logic copied from aws-amplify to add the ability to handle error cases
    */
    mySend() {
        const { username } = this.inputs;
        this.setState({errorMessage: null});
        Auth.forgotPassword(username)
            .then(data => {
                this.setState({ delivery: data.CodeDeliveryDetails });
            })
            .catch(err => {
                /* Example ForgotPass error messages:
                * - 'User does not exist.'
                */
                switch (err.code) {
                    case 'UserNotFoundException':
                        this.setState({errorMessage: "User does not exist."});
                        break;
                    default:
                        this.setState({errorMessage: err.message});
                        break;
                }
                this.error(err);
            });
    }
    /*
    * @brief submit a change-password request
    *
    * Logic copied from aws-amplify to add the ability to handle error cases
    */
    mySubmit() {
        const { username, code, password } = this.inputs;
        this.setState({errorMessage: null});
        Auth.forgotPasswordSubmit(username, code, password)
            .then(data => {
                this.changeState('signIn');
                this.setState({ delivery: null });
            })
            .catch(err => {
                /* Example ForgotPass error messages:
                * - 'Invalid verification code provided, please try again.
                * - '1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6'
                */
                this.setState({errorMessage: err.message});
                this.error(err);
            });
    }

    showComponent() {
        const error = this.state.errorMessage;
        const { hide } = this.props;
        if (hide && hide.includes(ForgotPassword)) { return null; }
        const buttonClick = (e) => {
            this.setState({errorMessage: null});
            this.changeState('signIn');
            e.preventDefault();
        };
        return (
        <FlatForm id="reset-password" title="Forgot Your Password?">
            <p className="text-inverse text-right">Back to <a href="#" onClick={buttonClick} >Sign In.</a></p>
            {error && <FlatErrorMessage>{error}</FlatErrorMessage>}
            { this.state.delivery ? this.submitView() : this.sendView() }
        </FlatForm>
        );
    }
}

/*
* @brief A component that allows a user to reset a password when forced to on initial login
*/
class FlatRequireNewPassword extends RequireNewPassword {
    constructor(props) {
        super(props);
        this.myChange = this.myChange.bind(this);
        this.state = this.state || {};
        this.state.errorMessage = null;
    }
    /*
    * @brief submit a change-password request
    *
    * Logic copied from aws-amplify to add the ability to handle error cases
    */
    myChange() {
        const user = this.props.authData;
        const { password } = this.inputs;
        const { requiredAttributes } = user.challengeParam;

        this.setState({errorMessage: null});

        Auth.completeNewPassword(user, password, requiredAttributes)
            .then(user => {
                if (user.challengeName === 'SMS_MFA') {
                        this.changeState('confirmSignIn', user);
                } else {
                this.changeState('signedIn');
                }
            })
            .catch(err => {
                /* Example ForgotPass error messages:
                * - '1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6'
                */
                this.setState({errorMessage: err.message});
                this.error(err);
            });
    }
    showComponent() {
        const error = this.state.errorMessage;
        const buttonClick = (e) => {
            this.setState({errorMessage: null});
            this.changeState('signIn');
            e.preventDefault();
        };
        return (
        <FlatForm id="reset-password" title="Password Reset Required">
            <p className="text-inverse text-right">Back to <a href="#" onClick={buttonClick} >Sign In.</a></p>
            {error && <FlatErrorMessage>{error}</FlatErrorMessage>}
            <FlatInput onChange={this.handleInputChange} placeholder="New Password" type="password" name="password" error={error} />
            <p className="text-inverse text-right">Password must be at least 6 letters long.</p>
            <FlatButton onClick={this.myChange} text="Change Password" />
        </FlatForm>
        );
    }
}

/*
* @brief Modal popup to show a password prompt
*/
class UserPasswordVerify extends PureComponent {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            shown: true,
            passwordCapture:null,
            loginStatus:0
        }
    }
    /*
    * @brief handle closing the card with submit
    */
    handleSubmit(_data){
        // console.log("Called submit",this.state,this.props);    
        this.setState({loginStatus: 0}); //reset the status
        try {
            const user = Auth.signIn(this.props.username, this.state.passwordCapture);
            user.then( data_=>{
//                 console.log("Sign in succeeded ",data_);
                this.setState({loginStatus: 1})

                window.setTimeout(() => {
                    this.props.handleClose(this.state.loginStatus===1?true:false)
                }, 1000); //delay closing for one second to show accepted message
                
            })
            .catch( error_=>{
                // console.log("Failed signin ",error_);
                this.setState({loginStatus: 2})
            })
            
        } catch (error) {
            console.log('error signing in', error);
        }
            

    }//end handleSubmit
    /*
    * @brief Render the content of the card:
    */
     render() {


        let loginMessage = null;
        switch(this.state.loginStatus){
            case 2:{
                loginMessage = <div className = "loginStatus">Unrecognized password, please try again</div>
            }break;
            case 1:{
                loginMessage = <div className = "loginStatus1">Password accepted</div>
            }
        }

        return (
            <div className="password-verify" >
                
                {/* {this.props.groupconfig.bLoaded? <ApiCaller apiCall={this.getApiCall} onApiResult={this.updateData} onLoadingState={this.onLoadingState} />:null}                 */}
                <ReactModal isOpen={this.state.shown} className="modal-dialog password-verify-modal"
                            shouldCloseOnOverlayClick={false}
                            onRequestClose={()=>{                                
                                this.props.handleClose(false)
                            }  }
                >
                    <div className="password-verify-content" >
                        
                        <div className = "title">Verify password {this.props.username}</div>

                        <input type="password" className="entry-field"  value={this.state.passwordCapture} placeholder="Password"                
                             onChange={(e) => {             
                                 console.log("On change: ",e);                    
                                 this.setState({passwordCapture:e.target.value});
                            }}
                        />

                        {loginMessage}

                        <div className="footer">
                            <div className="adding-buttons">
                                <button className="btn btn-danger" 
                                        onClick={ ()=>{
                                            // console.log("close sending: ",this.state.loginStatus);
                                            this.props.handleClose(this.state.loginStatus===1?true:false)
                                        }}
                                >Cancel</button>

                                <button className="btn btn-primary" 
                                    onClick={this.handleSubmit}
                                >Submit</button>
                            </div>
                        </div>

                    </div>
                </ReactModal>
            </div>
        );
    }
  }//end of UserRoleHelpPopup 

export { FlatSignIn, FlatGreetings, FlatForgotPassword, FlatRequireNewPassword,UserPasswordVerify };