import React, { Component } from 'react';
import './App.css';
import '../Filters/filters.css'

import { FileUploader,UploadForm } from '../FileUploader.js';
import { AnalyticsView } from '../AnalyticsView.js';
import { LiveView } from '../LiveTab/LiveView.js'
import { ReportingView } from '../ReportingView.js';
import { DocumentationView } from '../Documentation.js';
import { DashboardView } from '../Dashboard.js';
import { VideoDrilldownView } from '../VideoDrilldownView.js';
import { MapDisplayView as MapDisplayViewDev } from '../Map/MapDisplayView.js';
import { AdminReview } from '../AdminReview.js';
import { AssetReview } from '../ConnectivityView.js';
import { MonitorView } from '../Monitor.js';
import { AdminTabView } from '../AdminView.js';
import { CallinsView } from '../CallinsView.js';
import { RiskProfile } from '../RiskTab/RiskProfile.js';
import { StaffPerformanceView } from '../StaffPerformanceTab/StaffPerformance.js'
import { TrainDrivers } from '../DriverTraining/TrainDrivers.js';

import { VideoCutNotecardLoader } from '../VideoCut/VideoCutNotecard.js';

import Amplify from 'aws-amplify';
import {VIDEOREVIEW_NOREPORT} from '../Util-access.js'


const versionText = '10.09.2024'
// const versionCheckTimeout = 1*60*60*1000 //1 hour
const versionCheckTimeout = 30*60*1000 //1 min
const idleTimeout = 15*60*1000 //15 minutes
const DEFAULT_VIEW = "dashboard";

/*
* The configuration values that define our AWS assets and preferences.
*/
Amplify.configure({
    Auth: {    
      identityPoolId: 'us-west-2:9473ca32-373c-41f8-9f5a-cda4a7b88271',    // Amazon Cognito Identity Pool ID
      region: 'us-west-2', // Amazon Cognito Region
      userPoolId: 'us-west-2_mw1N9qfq1', // Amazon Cognito User Pool ID
      userPoolWebClientId: '4gmlbuepua59liisv6o6lp89fa', // Amazon Cognito App Client ID // 26-char alphanumeric string
      // Configure where the session details after login are stored, default is in localStorage
      // LocalStorage is shared among all tabs. Session Storage is restricted to a single tab
      storage: window.sessionStorage,
    },
    API: { 
      // Set the URL endpoint per service name, This URL comes from the output of 'serverless deploy -v' in the authlambda
      // The URLs can be configured to switch between the development and Release branches individually
      endpoints: [
        {
          name: "AuthLambda",
          service: "lambda",region: "us-west-2",
          // endpoint: "https://6kloyvzarl.execute-api.us-west-2.amazonaws.com/dev" 
          endpoint: "https://lfygs6nhb3.execute-api.us-west-2.amazonaws.com/release"
          // endpoint: "https://9att1qv37h.execute-api.us-west-2.amazonaws.com/devA"
        },
        {
          name: "TrifectaAPI",
          service: "lambda", region: "us-west-2",
          // endpoint: "https://4olpm6rugc.execute-api.us-west-2.amazonaws.com/dev"
          endpoint: "https://j09seepaa1.execute-api.us-west-2.amazonaws.com/release"
          // endpoint: "https://3nxg6rhe1b.execute-api.us-west-2.amazonaws.com/devA"
          
        },
        {
          name: "TrifectaCoreAPI",
          service: "lambda", region: "us-west-2",
          endpoint: "https://rzlssz55kc.execute-api.us-west-2.amazonaws.com/release"
          // endpoint: "https://fk453awni0.execute-api.us-west-2.amazonaws.com/devA"
        },
        {
          name: "DMSWS",
          service: "lambda", region: "us-west-2",
          endpoint: "wss://i94a7bdc5c.execute-api.us-west-2.amazonaws.com/dev"
        },
        {
          name: "UserManagement",
          service: "lambda", region: "us-west-2",
          // endpoint: "https://2ykpz460pl.execute-api.us-west-2.amazonaws.com/dev"
          endpoint: "https://894cfcnsle.execute-api.us-west-2.amazonaws.com/release"
        },
        {
          name: "VideoCutAPI",
          service: "lambda", region: "us-west-2",
          // endpoint: "https://6f9qzhxih7.execute-api.us-west-2.amazonaws.com/dev"
          endpoint: "https://sd00zw76ae.execute-api.us-west-2.amazonaws.com/release"
          
        },
        {
          name: "TrifectaMapAPI",
          service: "lambda", region: "us-west-2",
          // This URL comes from the output of 'serverless deploy -v' in the authlambda
          // endpoint: "https://my0v7q6yc6.execute-api.us-west-2.amazonaws.com/dev"
          endpoint: "https://olr4mpc9u4.execute-api.us-west-2.amazonaws.com/release"
        }
      ]
    },
    Storage: {
      bucket: 'e3d-is2y4ihl25',
      region: 'us-west-2',
      identityPoolId: 'us-west-2:9473ca32-373c-41f8-9f5a-cda4a7b88271'
    }
});

// Constants for what workflow state we're in in the main app
const READY = 0;
const UPLOADING = 1; 

/*
* @brief The main coordinating component of our application
* called from AppWithAuthInternal
*/
class App extends Component {
  constructor(props) {
    super(props);
    // Make functions reliably use 'this' regardless of calling context
    this.setFile = this.setFile.bind(this);
    this.uploadRequested = this.uploadRequested.bind(this);
    this.uploadResult = this.uploadResult.bind(this);

    this.getView = this.getView.bind(this);
    this.getPopups = this.getPopups.bind(this);

    // set up our state
    this.state = {
      "workflow": READY,
      "file": null,
      "error": null,
      "uploads": [],
      "group": null,
      
    };
    // set the group if we can, if not just ignore it
    try {
      this.state.group = this.props.authData.signInUserSession.idToken.payload['cognito:groups'][0];
    } catch (e) {}
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    // this function is called internally by react after component creation and sometimes updates
    // our 'props'. We're not actually receiving the group when this component is created, so we
    // have to check here

    // set the group if we can, if not just ignore it
    try {
      this.setState({group: nextProps.authData.signInUserSession.idToken.payload['cognito:groups'][0]});
    } catch (e) {}
  }
  componentDidMount() {    
    this.props.navEvents(this.navEvent);    
    
  }
  componentWillUnmount(){
    window.history.replaceState({mainView: "default", linkDataExtra: null}, "", "/");
  }


  /* Callbacks from internal components to update us on their results, like picking
     a file and upload results
  */
  setFile(f) {
    this.setState({"file": f, "error": null});
  }
  uploadRequested() {
    if (this.state.file) {
      this.setState({"workflow": UPLOADING, "s3auth": null});
    } else {
      this.setState({"error": "Please choose a file to upload."})
    }
  }
  uploadResult(err, res) {
    if (err) {
      this.setState({"error": err});
    } else {
      this.setState({"uploads": this.state.uploads.concat([res])});
      alert(res['filename'] + " successfully uploaded");
    }
    this.setState({"workflow": READY});
  }
  /* End of callbacks */
  getRenderElems(){
  }

  /**
   * Given the current view state, render the tab that has been selected
   */
  getView(){
    const workflow = this.state.workflow;
    const error = this.state.error;
    //
    
    let defaultView = DEFAULT_VIEW;
    if(this.props.groupconfig && this.props.groupconfig.group === 'reviewgroup'){defaultView = 'analytics'}
    const view = this.props.mainView || defaultView;

    // Set the CSS class of the #root element for per-page styling overrides that can't
    // be done any other way in the current layout
    document.getElementById("root").className = view;
    // console.log("Render view:" ,view);

    switch (view) {
      case 'analytics':
        {
          const video = this.props.linkDataExtra && this.props.linkDataExtra.video;
          return (
            <div className="box mainApp" id='analytics-page'>
              <AnalyticsView mainView={this.props.mainView} navEvents={this.props.navEvents}
                              eventNotify={this.props.eventNotify} video={video}
                              cardChange={this.props.cardChange}
                              groupconfig={this.props.groupconfig}
                              filter={this.props.filter}
                              possibleFilters={this.props.possibleFilters}
              />
            </div>
          );
        }
      case 'live':
        const video = this.props.linkDataExtra && this.props.linkDataExtra.video;
        
        return (
          <div className="box mainApp" id='liv-page'>
              <LiveView     mainView={this.props.mainView} navEvents={this.props.navEvents}
                            eventNotify={this.props.eventNotify} video={video}
                            cardChange={this.props.cardChange}
                            groupconfig={this.props.groupconfig}
                            filter={this.props.filter}
                            messageReceived={this.props.messageReceived}
                            possibleFilters={this.props.possibleFilters}
                            username = {this.props.username}
                            userInfo = {this.props.userInfo}
              />
            </div>
        );
      case 'upload':
        return (
          <div className="box mainApp" id='upload-page'>
            {error && <p className="error-message">{error}</p>}
            {workflow === READY && <UploadForm fileUpdated={this.setFile} upload={this.uploadRequested} />}
            {workflow === UPLOADING && <FileUploader file={this.state.file}
                                                      groupconfig={this.props.groupconfig}
                                                      auth={this.state.s3auth}
                                                      result={this.uploadResult}
                                        />}
          </div>
        );
      case 'dashboard':
        
        //Remove infractions from the filter that aren't required:
        const dashGroupConfig = {...this.props.groupconfig};
        dashGroupConfig.infractionTags =  (this.props.groupconfig.infractionTags || []).filter(tag_ => {
            if(tag_.type==="Ingress"){return false;}
            if(tag_.type==="Egress"){return false;}
            return true;
        })

        return (
          <div className="box mainApp" id='dashboard-page'>
            <DashboardView filter={this.props.filter} eventNotify={this.props.eventNotify}
                            defaultFilter={this.props.defaultFilter}
                            possibleFilters={this.props.possibleFilters}
                            groupconfig={dashGroupConfig}
                            username = {this.props.username}
              />
          </div>
        );
      case 'documentation':
        return (
          <div className="box mainApp">
            <DocumentationView />
          </div>
        );
      case 'reporting':
        return (
          <div className="box mainApp">
            <ReportingView defaultFilter={this.props.defaultFilter}
                          eventNotify={this.props.eventNotify}  
                          possibleFilters={this.props.possibleFilters}
                          filter={this.props.filter}
                          cardChange={VIDEOREVIEW_NOREPORT?null:this.props.cardChange}//{this.props.cardChange}
                          groupconfig={this.props.groupconfig}
                          liveonly={this.props.groupconfig.group.toLowerCase()==="reviewgroup_beirut"}
            />
          </div>
        );
      case 'live map':

        //Set up the filter options for the possible infractions
        const filtersMap = this.props.possibleFilters;
        filtersMap.infractiontags =  (this.props.groupconfig.infractionTags || []).map(tag_ => {
            return tag_.type;
        })
        //Check if the alertset is avaiable, if it is, then add it to the infraction tag set
        try {
            (this.props.groupconfig.alertset || []).forEach(tag_ => {
              filtersMap.infractiontags.push(tag_.type);
            })
        } catch (error) {
            console.log("failed to add alert set", error);
        }
        //Remove infractions from the filter that aren't required:
        let index = filtersMap.infractiontags.indexOf('Irrelevant');
        if (index > -1) { filtersMap.infractiontags.splice(index, 1); }
        index = filtersMap.infractiontags.indexOf('Positive Behaviour');
        if (index > -1) { filtersMap.infractiontags.splice(index, 1); }
        index = filtersMap.infractiontags.indexOf('Drowsiness');
        if (index > -1) { filtersMap.infractiontags.splice(index, 1); }
        // console.log("group:" ,this.props.groupconfig.group.toLowerCase());
        return (
          <div className="box mainApp">
            {/* <div className="no-map-message" >Coming Soon</div> */}
            {this.props.groupconfig.bLoaded && this.props.possibleFilters.AssetsTrifecta ? 
                <MapDisplayViewDev defaultFilter={this.props.defaultFilter}
                                possibleFilters={filtersMap}
                                groupconfig={this.props.groupconfig}
                                eventNotify={this.props.eventNotify}
                                cardChange={this.props.cardChange}
                              />
            :null}
          </div>
        );
      case 'video-drilldown':
        {
          const video = this.props.linkDataExtra && this.props.linkDataExtra.video;
          const filter = this.props.linkDataExtra && this.props.linkDataExtra.filter;
          const groupconfig = this.props.linkDataExtra && this.props.linkDataExtra.groupconfig;
          return (
            <div className="box mainApp">
              <VideoDrilldownView filter={filter} video={video}
                                  eventNotify={this.props.eventNotify}
                                  cardChange={this.props.cardChange}
                                  groupconfig={groupconfig}
                                  possibleFilters={this.props.possibleFilters}

              />
            </div>
          );
        }
      case 'adminreview':
        return (
          <div className="box mainApp">
            <AdminReview  groupconfig={this.props.groupconfig}                            
            />
          </div>
      );
      case 'connectivity':
        return (
          <div className="box mainApp">
            <AssetReview  groupconfig={this.props.groupconfig}
                          userInfo = {this.props.userInfo}
                          possibleFilters={this.props.possibleFilters}
            />
          </div>
      );
      case 'call-ins':
        return (
          <div className="box mainApp">
            <CallinsView  groupconfig={this.props.groupconfig}
                          userInfo = {this.props.userInfo}
                          cardChange={this.props.cardChange}
                          possibleFilters={this.props.possibleFilters}
                          defaultFilter={this.props.defaultFilter}
            />
          </div>
      );
      case 'risk profile':
        return (
          <div className="box mainApp">
              <RiskProfile  groupconfig={this.props.groupconfig}
                            userInfo = {this.props.userInfo}
                            cardChange={this.props.cardChange}
                            possibleFilters={this.props.possibleFilters}
                            defaultFilter={this.props.defaultFilter}
                            eventNotify={this.props.eventNotify}
              />
          </div>
      );
      case 'monitor':
        return (
          <div className="box mainApp">
            <MonitorView 
              groupconfig={this.props.groupconfig}
              possibleFilters={this.props.possibleFilters}
            />
          </div>
      );
      case 'admin':
        return (
          <div className="box mainApp">
            <AdminTabView 
                groupconfig={this.props.groupconfig}
                userInfo = {this.props.userInfo}
                possibleFilters={this.props.possibleFilters}
                username = {this.props.username}
            />
          </div>
      );
      case 'driver rankings':
        return(
          <div className="box mainApp">
            <div className="no-map-message" >Coming Soon</div>
          </div>
        );
      case 'staff performance':
        return(
          <div className="box mainApp">
            <StaffPerformanceView  groupconfig={this.props.groupconfig}
                                    userInfo = {this.props.userInfo}
                                    username = {this.props.username}
                                    possibleFilters={this.props.possibleFilters}
                                    defaultFilter={this.props.defaultFilter}
            />
          </div>
        );  
      case 'train drivers':
        return (
          <div className="box mainApp">
            <TrainDrivers   groupconfig={this.props.groupconfig}
                            userInfo = {this.props.userInfo}
                            possibleFilters={this.props.possibleFilters}
            />
          </div>
          
      );
      default:
        console.log("Page not found");
        return (
          <div className="box mainApp">
            Page not found
          </div>
        );
    }//end switch statement
  }//end getView()

  /**
   * Render popups that can be triggerd from the event notify
   */
  getPopups(){
    // console.log("LinkDataExtra: ",this.props.popup);
    // return null;
    const popup =this.props.popup; 
    const view = popup?popup.view:null;
    switch(view){
      default: return null;
      case 'videocut':{
        // console.log("VideoCut popup found", this.props.popup.linkDataExtra)
        const pk_id = (popup && popup.linkDataExtra)?popup.linkDataExtra.pk_id:null;
        return (
            <React.Fragment>
              {pk_id && <VideoCutNotecardLoader 
                  {...this.props.popup.linkDataExtra}
                  groupconfig={this.props.groupconfig}
                  userInfo={this.props.userInfo}
                  handleClose={()=>{
                    // console.log("Close returned?")
                    if(this.props.eventNotify){
                      this.props.eventNotify({
                          type: 'popup',
                          data: {
                            href: 'videocut',                                    
                            extra: null
                          },
                        });
                    }//end close popup
                  }}
              />}
            </React.Fragment>
              
            )//end return
      }break;
    }
  }//end getPopups()

  /**
   * Render the main page of the app. Depending on the current state switch which view/tab is open
   */
  render() {
    // console.log("View: ",this.props.mainView,this.props);
    // console.log("Possible filters: ",this.props.possibleFilters)
    // console.log("App: ",this.props.authState)
    const isSignedIn = this.props.authState === "signedIn" && this.props.userInfo;
    if (!isSignedIn) { return (<div className="box mainApp">Please refresh page</div>);}

    //Split up the rendered portions of the App into functions for organization
    return (
      <React.Fragment>
        {/*Render the page view   */}
        {this.getView()}

        {/*Render optional popups   */}
        {this.getPopups()}

      </React.Fragment>
    )
  }
}


export {App,versionText,versionCheckTimeout,idleTimeout};
