import { Auth, API } from 'aws-amplify';
/** Define a class that will manage sending updates to the API to audit user actions on the cloud service
 *  The objects to report data to send should be cached in internal queues that are sent in batches after the configured wait.
 *  This helps prevent the API and SQL server from being overloaded by individual connection requests and teardowns
 */
class APILoggerClass{
    /**
     * Initialize the member variables that are tracked by the class
     */
    constructor(){
        this.pendingQueue=[]; //Queue holding all pending log objects
        this.cacheconditions = {depth:5}; //default caching configuration, wait until 5 objects are received to send
        this.lastsend = new Date(); //track the last time a send was completed
    }

    /**
     * Configure the caching behavior
     * @param {*} _config: object describing caching strategy 
     */
    configure(_config){
        if(_config && _config.depth){ //configure the cache to wait for the object count
            this.cacheconditions.depth = _config.depth;
        }
        if(_config && _config.timeout){ //configure the cache to trigger based on time since last message
            this.cacheconditions.timeout = _config.timeout;
        }
    }//end configure

    /**
     * Add a new message to log to the API
     * @param {*} _logObject : object to write to the api
     * @param {*} _bForceSend : force the object to send right now, don't wait
     * @returns 
     */
    addLog(_logObject, _bForceSend = false){
        console.log("Add  a new log request: ",_logObject, _bForceSend);
        try {
            this.pendingQueue.push(_logObject); //add to the queue
            this.processQueue(_bForceSend?{send:true}:{});   
            return {status:"success"};
        } catch (error) {
            console.log("Failed to add: ",error);
            return {status:"fail"};
        }
    }//end addLog
   
    /**
     * Send updated values to the API
     * @param {*} _options: options to apply, current only supports .send, if defined then no cache conditions are considered
     */
    processQueue(_options={}){
        // return ;
        try {
            if(!this.pendingQueue){return {status:"NoData"};}
            //Get the depth threshold to be used to determine if an API call should be made
            
            let bShoudSend = false;
            //Evaluate the current pending queue against the configured cache conditions:
            try {
                if(this.cacheconditions.depth){
                    let queueDepth = this.pendingQueue.length; //get the size of the current queue:
                    if(queueDepth > this.cacheconditions.depth){bShoudSend = true;}    
                }
                if(this.cacheconditions.timeout){
                    let timeSinceLastSend = new Date() - this.lastsend; //calculate elapsed time since last send
                    if(timeSinceLastSend > this.cacheconditions.timeout){bShoudSend = true;}    
                }
            } catch (error) {console.log("Failed to evaluate send conditions: ",error);}
            
            // console.log("Pending items:",queueDepth,depthThreshold);
            //Prioritize the override value from _options, otherwise default to configured queue depth
            if(bShoudSend || _options.send){

                //Accumulate all data that has a status set (ready to send)
                let objectsToSend = []; //flattened array to send to the API                
                objectsToSend.push(...this.pendingQueue);
                if(objectsToSend.length ===0 )return {status:"success"}; //nothing to send, so don't trigger the API
                //Update the timer for the last send message
                this.lastsend = new Date();
                //Send the data to the API function:
                Auth.currentSession().then(
                (auth) => {
                    let apiName = "TrifectaAPI";
                    let path = "/handleAuditLogs";
                    let myInit = {
                        body: {
                            token: auth.idToken.jwtToken,
                            logs:objectsToSend,
                        }
                    };
                    //Send the update to the API
                    API.post(apiName, path, myInit)
                    .then(data=>{
                        this.pendingQueue=[]; //clear out the pending values
                        if(!data.result){return;}
                    })//end then
                    .catch( error=>{
                        console.log("Fail on get clips: ",error);
                        this.pendingQueue=[]; //clear out the pending values
                    });//end catch
                });
            }//end bShouldSend evaluation
            return {status:"success"};
        } catch (error) {
            console.log("Failed process: ",error);
            return {status:"fail"};
        }
    }//end processQueue
   
    /**
     * Send all remaining data before shutdown
     */
    emptyQueue(){
        let returnVal = this.processQueue({send:true});//set depth to one to trigger immediate sync
        // console.log("Call emtpy", returnVal);
    }//end emptyQueue
}
//Create a single instance that is shared among all callers
export const APILogger = new APILoggerClass();
