import React, { Component } from 'react';
import './App.css';
import '../Filters/filters.css'

import { App,versionText,versionCheckTimeout,idleTimeout } from './App.js';
import { AuthHelper } from '../AuthHelper.js';
import { FlatSignIn, FlatGreetings, FlatForgotPassword, FlatRequireNewPassword } from '../Authentication/FlatAuth.js';
import { NavBar } from '../NavBar/NavBar.js';
import { filterAssetSet } from '../Util-asset.js';
import { WebsocketInterface } from '../WebsocketInterface.js';

import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import {authenticateBrowser} from '../Authentication/BrowserAuthLogin.js';
import { isRegistrationRequired,RegisteredLogin } from '../Authentication/RegisteredLogin.js';
import { getCookie } from '../Util-access';

import { perfDidClick } from '../Perf.js';

import { API, Auth } from 'aws-amplify';
import { Authenticator } from 'aws-amplify-react';
import { DefaultInfractionSet,DefaultDisplayNameSet } from '../Util.js';

import IdleTimer from 'react-idle-timer'
import * as moment from 'moment';

import DialogBox from 'react-modeless'

const TAB_RECORD_DELAY = 1; // in minutes
const TAB_NUMBER = Math.floor(Math.random() * 1000000000);
// A convenient name for the type of storage we are using for recording tabs
// This may be used to change to window.sessionStorage (or other possible values)
// if desired.
// const storage = window.localStorage;
const storage = window.sessionStorage; //Use sessiont storage so that it is private to a single tab


const updateRecord = () => {
  // console.log("Update tab record");
  storage.setItem('tab-record-' + TAB_NUMBER, Date.now());
  storage.setItem('SessionNumber',TAB_NUMBER);
  window.localStorage.setItem('tab-record-'+TAB_NUMBER,Date.now()); //Keep this recorded in the local storage, shared between all tabs
};



const DEFAULT_VIEW = "dashboard";
const DEFAULT_FILTER = {
  time: "all",
  driver: "no-driver-selected",
  driverid: null, //for user permissions,
  siteIDs: null,
  role: null,
  crew: null,
  infraction: "all",
  graph: 'both',
  quant: 30,
  // siteIDs: ['Boddington','Whitehaven'],
};
const DEFAULT_GROUP_CONFIG ={
  infractionSet: [],
  displayNameSet: null,
  disabledInfractionSet: [], 
  infractionTags:[],
  bLoaded: false,
  group: null,
  mode: null,
  driverid:null,
};


/*
* @brief Wraps the main App component with the authenticator logic and some styling necessities
* This is the component that is render by index.js 
*/
class AppWithAuthInternal extends Component {
  constructor(props) {
    super(props);
    this.authStateChange = this.authStateChange.bind(this);
    this.extractCustomAttributes = this.extractCustomAttributes.bind(this);
    this.executeAuthAPIRequest = this.executeAuthAPIRequest.bind(this);
    this.repeatFilterCall = this.repeatFilterCall.bind(this);
    this.navEvent = this.navEvent.bind(this);
    this.registerSignOutListener = this.registerSignOutListener.bind(this);
    this.registerNavEventListener = this.registerNavEventListener.bind(this);
    this.handleHistoryPopState = this.handleHistoryPopState.bind(this);

    this.updatePossibleFilters = this.updatePossibleFilters.bind(this);
    this.updatePossibleConfig = this.updatePossibleConfig.bind(this);
    this.evalVersionText = this.evalVersionText.bind(this);
    
    this.addVideoCardChange = this.addVideoCardChange.bind(this);
    this.messageReceived = this.messageReceived.bind(this);

    this.onClose = this.onClose.bind(this)

    this.idleTimer = null
    this.handleForceLogout = this.handleForceLogout.bind(this);
    this.handleLoginEvents = this.handleLoginEvents.bind(this);


    this.m_videoCardChanges = [];
    this.m_videoCardRequestInProgress = false;
    this.state = {
      group: null,
      username: null,
      mainView: DEFAULT_VIEW,
      popup:null,
      filter: DEFAULT_FILTER,
      possibleFilters: JSON.parse(JSON.stringify({})),
      linkDataExtra: null,
      userInfo: null,
      groupconfig: JSON.parse(JSON.stringify(DEFAULT_GROUP_CONFIG)),
      bNewClipRecieved: false,
      versionInterval: null,
      bShowVersionDialog: 0, //initialize to 0:off, 1: reminder, 2: full screen
      forcedOut: null,
      login_state: null,
    };
    // linkDataExtra is extra data that goes along with the view we are currently on
    // it is conceptually similar (but not implemented the same) as the 'mainView' being
    // a URL and the linkDataExtra being the part after the ? in the URL
    this.signOutListeners = [];
    this.navEventListeners = {};
    this.nextNavEventKey = 1;
    window.onpopstate = this.handleHistoryPopState;
  }
 
  componentDidMount() {
    
    if (window.history.state) {
      // If there is already a history state, use it
      // this happens eg if the user clicks reload in the browser
      // console.log("Initial history state found? : ",window.history, this.state);
      this.handleHistoryPopState(window.history);
    } else {
      // console.log("No history state : ",this.state);
      // If there is no history state (ie when someone types in the URL for the page), set the
      // initial state so the back button can navigate to here successfully
      // console.log("Setting initial history state");
      window.history.replaceState({mainView: 'default', linkDataExtra: this.state.linkDataExtra}, "", "/");
    }
    updateRecord();
    this.setState({recordInterval: setInterval(updateRecord, TAB_RECORD_DELAY * 60 * 1000)});
    //Set the repeat interval
    // console.log("Set repeat interval?");
    // this.setState({ versionInterval: setInterval(this.repeatFilterCall(),10000) });

  } //end componentDidMount
  componentWillUnmount(){
    console.log("release the interval?")
    if(this.state.versionInterval){clearInterval(this.versionInterval);}
    if(this.state.recordInterval){clearInterval(this.state.recordInterval);}
  }
  messageReceived(){
    // console.log("App got the message")
    this.setState({bNewClipRecieved:true});//trigger a new event
    setTimeout(() => {this.setState({bNewClipRecieved: false});}, 5000);
  }

  /*
  * @brief Called when a history state is popped
  *
  * This happens when the user presses Back/Forward in their browser and may happen when the page is loaded.
  *
  * We use this to set the 'view' we should be showing, for example the Dashboard or the Reports page
  */
  handleHistoryPopState(event) {
      // console.log("Pop State: ",event,event.state);
      const state = event.state;
      if (state && state.mainView) {
        this.setState({mainView: state.mainView, linkDataExtra: state.linkDataExtra});
      }
  }

  extractCustomAttributes(_cognitoUser,_groupname){
      //Get the current user data, custom attributes from cognito:
      Auth.currentUserInfo().then(auth => {
        // console.log("Current auth user: ",auth, data, state);
        let userData = {
          bLoaded:false
        }
        if(auth){userData.username = auth.username;}
        //This needs to be explicitly reset: apparently the DEFAULT_FILTER is linked to 
        //the changes of the state.filter so it gets udpated as well and can't be trusted to reset the
        //original filter

        //Execute a deep copy so that the changes to the currentFilter aren't applied to the DEFAULT_FILTER
        const currentFilter = JSON.parse(JSON.stringify(DEFAULT_FILTER));
        // currentFilter.siteIDs=null;
        // currentFilter.role=null;
        // currentFilter.crew=null;
        // currentFilter.driverid=null;
        
        if(auth && auth.attributes){ 
          for( const [key_,value_] of Object.entries(auth.attributes)) {
            //Look for the allowedSites attribute, set filter if present, helps
            // console.log("Key: ",key_);
            //Handle the attributes
            switch(key_){
              case 'custom:AllowedSites':{
                userData.allowedSites=auth.attributes[key_].split(',');
                // console.log("Loaded: ",userData.allowedSites);
                if(userData.allowedSites && userData.allowedSites[0]===' '){
                  currentFilter.siteIDs = null;
                }else{
                  currentFilter.siteIDs = userData.allowedSites;  
                }
              }break;
              //Look for the Role attribute, helps determine read/write priviliges
              case 'custom:Role':{
                userData.role=auth.attributes[key_].split(',');
                if(userData.role && userData.role[0]!==' '){
                  currentFilter.role = userData.role;  
                }else{
                  currentFilter.role = null;  
                }
              }break;
              //Look for the Crew attribute, this filters the possible driver ids
              case 'custom:Crew':{
                userData.crew=auth.attributes[key_].split(',');
                if(userData.crew && userData.crew[0]!==' '){
                  currentFilter.crew = userData.crew;  
                }else{
                  currentFilter.crew = null;  
                }
              }break;
              //Look for the DriverID attribute, this filters the possible driver ids
              case 'custom:DriverID':{
                userData.driverid=auth.attributes[key_].split(',');
                if(userData.driverid && userData.driverid[0]!==' '){
                  currentFilter.driverid = userData.driverid[0];  
                  currentFilter.driver= userData.driverid[0];  
                }else{
                  currentFilter.driverid = null;  
                }
              }break;
              //Look for the AllowedTabs attribute, this when set will dictate which tabs can be seen by the user
              case 'custom:AllowedTabs':{
                // console.log("parse allowed tabs", auth.attributes[key])
                userData.allowedTabs=auth.attributes[key_].split(',');  
              }break;
              //Look for allowed groups, used to show expanded views for super users
              case 'custom:AllowedGroups':{
                userData.allowedGroups=auth.attributes[key_].split(',');  
              }break;
              //Look for the option to disable the idle timeout
              case 'custom:noIdle':{
                userData.noIdle=auth.attributes[key_].split(',');
              }break;
              case '':{
              }break;
              case '':{
              }break;
              //Handle custom attributes
              default:{
                if(key_.includes("custom:")){
                  let attributeName = key_.replace('custom:','');
                  attributeName = attributeName.charAt(0).toLowerCase()+attributeName.substring(1);
                  //Is this a string type?
                  if(typeof value_ === 'string'){
                    if(value_.includes(',')){ //is this a csv string?                      
                      userData[attributeName] = value_.split(',');
                    }else{
                      userData[attributeName] = value_;
                    }
                  }
                  else{
                    userData[attributeName] = value_;
                  }

                }
              }break;


            }
     
          };//end of for loop
          userData.bLoaded = true;
        }
        if(userData.allowedTabs && userData.allowedTabs.length > 0){
          let newView = DEFAULT_VIEW;
          try {
            
            newView = userData.allowedTabs[0].toLowerCase();
            if(newView !=='admin'){//don't redirect for this one
              // console.log("Set new view in allowetabs", newView)
              this.setState({userInfo: userData, filter:currentFilter,mainView:newView});
            }else{
              this.setState({userInfo: userData, filter:currentFilter});
            } 
          } catch (error) { }
          
        }else{
          this.setState({userInfo: userData, filter:currentFilter});
        }

        
        //Start the idle timer: 
        //  console.log("Check user rules: ",userData);
          if(userData.noIdle){
            // console.log("No idle by user");
          }else{
            let noIdleGroup = ['reviewgroup_beirut', 'devgroup', 'testinggroup'] //define the list of groups that don't have an idle timer
            if(!noIdleGroup.includes(_groupname)){
              console.log("Idle Group: ",_groupname);
              if(this.idleTimer){this.idleTimer.start();}
              if(this.idleTimer){this.idleTimer.reset();}
            }
           
          }
          
        //get the filters and group configuration
        this.executeAuthAPIRequest(_cognitoUser,userData)
      });
  }

  //AWS Amplify States: idle, setup, signIn, signUp, confirmSignin, confirmSignUp, setupTotp, forceNewPassword, forgotPassword, confrimResetPassword, verifyUser, signout, authenticated
  authStateChange(state, data) {
    
    let restrictedLoginState = ['notRegistered','register','blocked'];
    // console.log("Auth state change: ",state,data,this.state.group,JSON.parse(JSON.stringify(this.state.groupconfig)));    
    
    if (state !== "signedIn" && !restrictedLoginState.includes(state)) {
      //Process the logout 
      let stateObj ={ group: null, 
                      username: null, 
                      mainView: DEFAULT_VIEW, 
                      linkDataExtra: null,
                      popup:null,
                      filter: DEFAULT_FILTER, 
                      userInfo: null, 
                      possibleFilters: JSON.parse(JSON.stringify({})),
                      groupconfig: JSON.parse(JSON.stringify(DEFAULT_GROUP_CONFIG)),
                      login_state:null,
                      bShowVersionDialog:0
                    };
      
      this.setState(stateObj, 
                    // console.log("Updated state: ",this.state,JSON.parse(JSON.stringify(DEFAULT_GROUP_CONFIG)))
                  );
      //Pause the idle timeout:
      if(this.idleTimer){this.idleTimer.pause();}
    } else {
      //make sure there is a session number
      if(!window.sessionStorage.getItem('SessionNumber')){
        console.log("Sign in set the session number");
        updateRecord();
      }

      this.setState({forcedOut:null});
      try {
       
        let mainView = DEFAULT_VIEW;
        const login = data.signInUserSession.idToken.payload;
        let bNewGroup = false;
        const username = login['cognito:username'];       
        const newGroup = login['cognito:groups'][0];
        console.log("newGroup check: ",newGroup, this.state.group);
        if (newGroup && newGroup !== this.state.group) {
          bNewGroup = true;
        }

        
        let updateObject = {  group: newGroup
                              ,username: username
                              ,login_state:state
                              // ,bShowVersionDialog:0
                            };

        // if(newGroup === 'reviewgroup'){mainView = 'analytics'}
        // this.setState({group: newGroup, mainView: mainView, username: login['cognito:username']});
        if(newGroup === 'reviewgroup' && this.state.mainView === 'dashboard'){
          updateObject.mainView = 'analytics'
          // console.log("Set mainview: reviewgroup")
          // this.setState({mainView: mainView});
        }
        if(newGroup === 'reviewgroup_beirut' && this.state.mainView === 'dashboard'){
          updateObject.mainView = 'live'
          // console.log("Set mainview: beirutgroup")
        }
        
        this.setState(updateObject);

        if(bNewGroup){
          console.log("Extract attributes");
          this.extractCustomAttributes(data,newGroup);
        }
        
      } catch (e) {
        console.log("Sign in error: ",e);
        this.setState({group: null, username: null});
      }
    }
  }//end AuthStateChange

  repeatFilterCall(){

      Auth.currentSession().then(
        (auth) => {
          let apiName = "AuthLambda";
          let path = "/getPossibleFilters";
          let myInit = {
            body: {
              token: auth.idToken.jwtToken,
            }
          };
          return API.post(apiName, path, myInit);
        })
        .then(_data=>{
          _data.fromRepeat= true;
          this.updatePossibleFilters(_data)
      });

  }

   /*
  * @brief Execute the API requests that depend on current Auth Session:
  */
  executeAuthAPIRequest(data,_userData) {

      
    //  console.log("Auth API request: ",_userData,data,this.state.group,this.state.userInfo);
      try {
          
          const token = data.signInUserSession.idToken.jwtToken;
          let apiName = "AuthLambda";
          let path = "/getPossibleFilters";
          let myInit = {
            body: {
              token: token,
            }
          };
          API.post(apiName, path, myInit).then(this.updatePossibleFilters);

          let userRole = null;
          try {
            userRole = _userData.role[0];
          } catch (error) {           
          }
          //Make a lambda call to retrieve the group configuration - infraction names, display names, etc.
          let pathConfig = "/getGroupConfig";
          let myInitConfig = {
            body: {
              token: data.signInUserSession.idToken.jwtToken,   
              role: userRole             
            }
          };
          
          API.post(apiName, pathConfig, myInitConfig)
          .then((response_)=>{
            let username = data.signInUserSession.idToken.payload['cognito:username'];
            this.updatePossibleConfig(response_,username)
          })
          .catch(err => {
            console.log("Error in get filter call: ",err)
            AuthHelper.refreshCurrentSession().then(
              this.executeAuthAPIRequest(data,this.state.userInfo)
            );
            // this.executeAuthAPIRequest(data);
          })  
          
      } catch (e) {

      }
  }

  /*
  * @brief Update the values that are possible filters
  * 
  * For example this data is used to populate the DriverID filter dropdown with
  * existing DriverIDs
  */
  updatePossibleFilters(data) {
    // console.log("Possible Filter return: ",JSON.parse(JSON.stringify(data)));
    // Sort DriverIDs by natural sorting (numeric where possible)
    const comparator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
    // if(data.result && data.result.DriverID){data.result.DriverID = data.result.DriverID.sort(comparator.compare);}
    if(data.result && data.result.DriverID){data.result.DriverID = data.result.DriverID.sort();}
    if(data.result && data.result.Assets){data.result.Assets = data.result.Assets.sort(comparator.compare);}
    if(data.result && data.result.AssetsTrifecta){
        data.result.AssetsTrifecta = data.result.AssetsTrifecta.sort(comparator.compare);
    }

    if(data.result && data.result.Sites){
      data.result.Sites = data.result.Sites.sort(comparator.compare);
      data.result.gpsSites = (data.result.Sites||[]).filter(site_=>{ return site_.gps?true:false})

      //tag the site that have a Trifecta Asset present:
      let trifectaSites = null;
      trifectaSites = (data.result.AssetsTrifecta||[]).map( elem_ => elem_.site.toLowerCase());
      trifectaSites = [...new Set(trifectaSites)]; //remove the duplicates
      // Compare the sites, if the names match then enable the site as a Trifecta site 

      //Create a list to hold the sites
      let sitesWithTrainedDrivers = [];
      //populate if the data was returned (force lower case)
      if(data.result && data.result.TrainedSites){
        sitesWithTrainedDrivers = (data.result.TrainedSites||[]).map(site_=>{return site_.toLowerCase()})
        //remove this from the data.result object so that it isn't passed on to the possibleFilters state
        delete data.result.TrainedSites; 
      }
      

      //(can be used on) Trifecta only tabs (like RiskProfile)
      (data.result.Sites||[]).forEach(site_ =>{
        site_.cloudtype = 'Offline';
        let sitenameLC = site_.site.toLowerCase();
        //Are there active Trifecta Assets at the site
        if(trifectaSites.includes(sitenameLC) ){
          site_.cloudtype = 'Trifecta';
        }
        //Were DriverIDs trained at the site
        if(sitesWithTrainedDrivers.includes(sitenameLC) ){
          site_.cloudtype += ',DriverID';
        }

      })
      // let trifectaSites = (data.results.AssetsTrifecta||[]).filter( asset_=>)
    } //end processing sites data
    data.result.bLoaded = true;
    //Get the drivers?
    let siteList = [];
    if(data.result && data.result.Sites){
        //Extract the name of the sites from the site,gps pair
        (data.result.Sites || []).forEach(row_=>{  siteList.push(row_.site); })
    } 
    //  console.log("Pass sitelist: ",siteList)

    //Check the versions to force refresh:
    this.evalVersionText(data);

    // let timeStart = new Date();
    const realPromise = Auth.currentSession().then(
      (auth) => {
          let apiName = "TrifectaAPI";
          let path = "/handleDrivers";
          let myInit = {
              body: {
                  token: auth.idToken.jwtToken,
                  clientid: data.result.groupName,
                  sites: siteList,
                  mode: 'fetch',              
                    
              }
          };
          return API.post(apiName, path, myInit);
    });
    realPromise.catch((error) => {
      console.error("Fail on call to get drivers; ",error); 
    })
    realPromise.then((data2)=>{
      // let timeEnd = new Date();
      //  console.log("Got driver Data: ",new Date() - timeStart );
      // if(data2 && data2.drivers){data.result.DriverID = data2.drivers.sort();}
      if(data2 && data2.drivers){data.result.DriverID = data2.drivers.sort((a,b) => a.driverid < b.driverid?-1:0);}

      
      // Then set the state
      this.setState({possibleFilters: data.result});
    }); 
  }

  evalVersionText(data){


    //Trigger the login evaluate with IP location (record the successful login)
    try{
      Auth.currentSession().then(_auth=>{
          authenticateBrowser(_auth,{localversion: versionText,cloudversion:data.result.CloudVersion});
        }
      );
    }catch(error){}
    //Check the version on the Cloud:
    try{
      console.log("Version check: ",data.result.CloudVersion,versionText)
      if(data.result.CloudVersion && data.result.CloudVersion !== versionText){
        console.log("Not equal, cloud version newer: ",moment(data.result.CloudVersion, 'MM.DD.YYYY').isAfter(moment(versionText, 'MM.DD.YYYY')))
        // if(moment(versionText,))
        //Check if the cloud version is newer:
        if(moment(data.result.CloudVersion, 'MM.DD.YYYY').isAfter(moment(versionText, 'MM.DD.YYYY'))){
          console.log("Version Check: ",moment(data.result.CloudVersion, 'MM.DD.YYYY'), moment(versionText, 'MM.DD.YYYY'),moment(data.result.CloudVersion, 'MM.DD.YYYY').isAfter(moment(versionText, 'MM.DD.YYYY')))
          //Display the notification in the bottom left
          if(data.fromRepeat){
            if(this.state.bShowVersionDialog!==2){
              this.setState({ bShowVersionDialog: 1 })
            }            
          }else{ //This came from login, display the center message
            console.log(`Force refresh message cloud _${data.result.CloudVersion}_ vs cache _${versionText}_`);
            this.setState({ bShowVersionDialog: 2 })
          }
        }
          
      }
      setTimeout(() => {this.repeatFilterCall()}, versionCheckTimeout);
    }catch(error){console.log("Fail on version check: ",error,data.result, versionText);}
  }

   /*
  * @brief Update the configuration params that are unique to the group: infraction list, display names, and ignore list
  */
  updatePossibleConfig(data,username) {
    // console.log("Possible config: ",data,username); 
    // console.log("Update groupconfig: ",JSON.parse(JSON.stringify(DEFAULT_GROUP_CONFIG)))
    
    let newConfig = {
      infractionSet : DefaultInfractionSet      //Set the infraction list to default values
      ,driverInfractionSet : []                  //Set the driver infraction list to default values
      ,displayNameSet : DefaultDisplayNameSet    //Set the display names to default values
      ,disabledInfractionSet : []                //Set the ignored list to empty
      ,infractionTags : []                       //the list of allowed infraction types
      ,roles : []                                //The allowed list of roles for this group
      ,mode : null
      ,displayedInfractions : []                 //Set the infraction list to default values
      ,permissions : null                        //Place holder for the returned permissions data
      ,alerttypes : []                           //The list of allowed alert types
      ,alertset : []                           //The list of allowed alert types
      ,asset_regex : null                        //The list of allowed alert types
      ,group:null
    } ;
    try {
      
      if(data.config[0].infractionset){
        newConfig.infractionSet = data.config[0].infractionset.split(",");     // load the infractions from SQL
      }
      if(data.config[0].displayedinfractions){
        newConfig.displayedInfractions = data.config[0].displayedinfractions.split(",");     // load the infractions from SQL
      }
      if(data.config[0].driverinfractionset){
        newConfig.driverInfractionSet = data.config[0].driverinfractionset.split(",");     // load the infractions from SQL
      }
      if(data.config[0].displaynameset){
        newConfig.displayNameSet = JSON.parse(data.config[0].displaynameset);    // load the names from SQL
      }
      if(data.config[0].disabledinfractionset){
        newConfig.disabledInfractionSet = data.config[0].disabledinfractionset.split(","); // load the disabled list from SQL
      }
      if(data.config[0].infractiontags){
        let tmp = data.config[0].infractiontags.split("|"); // load the disabled list from SQL
        // console.log("tmp: ",tmp)
        newConfig.infractionTags = tmp.map(set_=>{
          return JSON.parse(set_);
        });
        // console.log("Loaded:" ,newConfig.infractionTags_,data.config[0].infractiontags)
      }
      if(data.config[0].roles){
        newConfig.roles = data.config[0].roles.split(","); // load the list of roles from groupconfig
      }
      //Convert the list of alerts into an array:
      if(data.config[0].alerttypes){ newConfig.alerttypes = data.config[0].alerttypes.split(",");}
      if(data.config[0].alertset){ newConfig.alertset = data.config[0].alertset;}
      // if(data.config[0].alerttypes){ newConfig.alerttypes_ = data.config[0].alerttypes.split(",");}
      //Set the permissions if found
      // console.log("Set the permissions:" ,data,data.permissions)
      if(data.permissions && Object.keys(data.permissions).length > 0){
        newConfig.permissions = data.permissions;
      }
      if(data.config[0].asset_regex && Object.keys(data.config[0].asset_regex).length > 0){
        newConfig.asset_regex = data.config[0].asset_regex;
      }
      newConfig.mode = data.config[0].mode || null;
    } catch (error) {      
      console.log("Config update failed: ",error);  
    }
    // 
    //Set the loaded values as the new norms
    this.setState(prevState => {
      try{
        const groupconfigTmp = Object.assign( prevState.groupconfig,newConfig);
        
        // console.log("GCTEmp:" ,groupconfigTmp,prevState.filter);
        //If we can't get the group value from the authdata, grab it from the API return token
        if(!prevState.group){
          try { groupconfigTmp.group = data.config[0].group; } catch (e) {}
        }
        else{
          groupconfigTmp.group = prevState.group;
        }

        
        if(groupconfigTmp.group){     groupconfigTmp.bLoaded = true;    }
        // groupconfigTmp.mode = mode_;
        // console.log("Setting groupconfig: ",groupconfigTmp, prevState);
        return {groupconfig:JSON.parse(JSON.stringify(groupconfigTmp))};
      }catch(err){
        console.log("Error setting groupconfig: ",err);
      }
    },
    //Group Config has been updated
    ()=>{
      // console.log("Assigned groupconfig: ",JSON.parse(JSON.stringify(this.state.groupconfig)))
      //Register the websocket with the group details
      WebsocketInterface.init(username,this.state.groupconfig);
    }
    ); 
    // this.setState({infractionSet: infractionSet_,displayNameSet:displayNameSet_,disabledInfractionSet:disabledInfractionSet_ });  
  }

  navEvent(e) {
    //  console.log("Event: ", e, Date.now());
    if (e.type === 'signout') {
      // return;
      //  console.log("signing out");
      for (var i = 0; i < this.signOutListeners.length; ++i) {
        try {
          this.signOutListeners[i]();
        } catch (e) {};
      }
      //Clean up storage values:
      let sessionNumber = window.sessionStorage.getItem('SessionNumber');
      window.localStorage.removeItem(sessionNumber);

      WebsocketInterface.close();
      
      // prevent automatic login 
      window.sessionStorage.clear();// window.localStorage.clear();
      //Reset the login state:      
      // console.log("Signout:" ,DEFAULT_GROUP_CONFIG);
      // console.log("Set default view on signout")
      this.setState({group: null, username: null, mainView: DEFAULT_VIEW, linkDataExtra: null, popup:null,
                      filter: DEFAULT_FILTER, userInfo: null, possibleFilters: JSON.parse(JSON.stringify({})),groupconfig: JSON.parse(JSON.stringify(DEFAULT_GROUP_CONFIG))}
                      , 
                      // ()=>{console.log("Updated state 2",this.state);}
                    );

    } else if (e.type === 'link') {
      perfDidClick(e.data.href);
      //  console.log("Click: ",e.data);
      // console.log("set default view on link",e.data.href)


       this.setState({mainView: e.data.href, linkDataExtra: e.data.extra});
       window.history.pushState({mainView: e.data.href, linkDataExtra: e.data.extra,}, "", "/");
    } else if (e.type === 'filter') {
      const orig = Object.assign({}, this.state.filter);
      Object.assign(orig, e.data);
      this.setState({filter: orig});
    } else if (e.type ==='popup'){
      // console.log("Event: ",e);
      this.setState({popup: {view: e.data.href, linkDataExtra: e.data.extra}});
    }
    for (var k in this.navEventListeners) {
      if (this.navEventListeners.hasOwnProperty(k)) {
        try {
          this.navEventListeners[k](e);
        } catch (e) {};
      }
    }
  }

  registerSignOutListener(f) {
    // console.log("Signout listener: ",f);
    this.signOutListeners.push(f);
  }

  registerNavEventListener(f, key) {
    if (!key) {
      key = this.nextNavEventKey;
      this.nextNavEventKey++;
    }
    this.navEventListeners[key] = f;
  }
  /*
  * @brief Queue up a changed Video Card for sending to the backend for persistence
  * 
  * @param card The Video Card data to send
  * @param    .delete true if the VideoCard should be deleted from the backend
  */
  addVideoCardChange(card) {
    // card may be undefined as used internally (we use that to recurse into ourself)
    // callers should not do this though, it is not part of the API of this function
    // console.log("Video card change: ",card);
    if (card && validCardChange(card)) {
      // console.log("Card change valid: ",card);
      card.username = this.state.username;
      this.m_videoCardChanges.push(card);
    } else if (card) {
      console.log("Video card change invalid ",card);
      return;
    }
    // console.log("Card changes:" ,this.m_videoCardChanges);
    // If there isn't a request in progress, start one
    if (!this.m_videoCardRequestInProgress && this.m_videoCardChanges.length > 0) {
      this.m_videoCardRequestInProgress = true;
      this.setState({saving: true});
      // Do the actual API call
      Auth.currentSession().then(
        (auth) => {
          let apiName = "AuthLambda";
          let path = "/saveVideoCards";
          let myInit = {
              body: {
                  token: auth.idToken.jwtToken,
                  cards: this.m_videoCardChanges,
              }
          };
          return API.post(apiName, path, myInit);
      }).then(res => {
        // Check our results, discard the changes that succeeded (they're stored on the server now)
        // console.log("Update return: ",res)
        this.m_videoCardChanges = this.m_videoCardChanges.slice(res.updated);
      }).catch(err => {
        console.log("Card change error:", err);
      }).finally(() => {
        // Clear out our in progress flag, and if there was more to do, start again by recursing
        this.m_videoCardRequestInProgress = false;
        if (this.m_videoCardChanges.length > 0) {
          this.addVideoCardChange();
        } else {
          this.setState({saving: false});
        }
      });
    }
  }

  /* handle the dismiss button press for the version notification */
  onClose(e) {
    // console.log("Dismiss clicked")
    this.setState({ bShowVersionDialog: 0 })
	}

  /* handle forcing log out, idle, or credential based*/
  handleForceLogout(_event){
    let stateObj={forcedOut:_event};
    if(_event === 'idle'){
      stateObj.bShowVersionDialog=0;
    }
    this.setState(stateObj);
    Auth.signOut();
  }

  //** Receive all authorization events from Amplify Auth, log when a signin failure occurs */
  handleLoginEvents(_event){
    // console.log("Auth e: ",_event);
    //Split the received object into parts
    const { event, data, message } = _event;
    switch (event) {
        case 'signIn_failure':{
          let apiName = "TrifectaCoreAPI";
          let path = "/handleLogin";
          try {
            let myInit = {
              body: {
                mode: 'log',
                event: event,
                username: message.split(" ")[0],
                token: getCookie('registered-token')
              }//end body declaration
            };
            console.log("post to API: ")
            return API.post(apiName, path, myInit);  
          } catch (error) {
            console.log("Failed to post: ",error);
          }
        }break;
        default:
            break;
    } //end switch
  }//end handleLoginEvents

  /**
   * Render the page content, returns the HTML JSX to the React engine   
   */
  render() {    
    // console.log("Authed App: ",this.state,this.state.authState);
    //Create the version dialog
    let  versionDialogModeless = <DialogBox
                                    isOpen={this.state.bShowVersionDialog===1} containerClassName={'version-notify'} onClose={this.onClose}
                                    style={{
                                      background:'rgb(111,216,255)',
                                      width:'25vw',
                                      height:'6.5vh',
                                      position:'fixed',
                                      top:'unset',
                                      bottom: '1%',
                                      left: '1%',              
                                      transform: 'unset',
                                      borderRadius: '10px',
                                    }}
                                >
                                    <div className='version-notify-content'>
                                      <div className='version-notify-text'>
                                        <div>  A new version is available, please refresh   </div>
                                        <div> (ctrl+F5 or cmd+shift+r)   </div>
                                      </div>
                                      <div className='version-notify-button'>
                                        <button classname='version-notify-btn' onClick={this.onClose}>Dismiss</button>
                                      </div>
                                  </div>
                                </DialogBox>

    let  versionDialogModal = <DialogBox
                                isOpen={this.state.bShowVersionDialog===2} containerClassName={'version-notify'} onClose={this.onClose}  noBackdrop={false}
                                clickBackdropToClose ={false}
                                style={{
                                  background:'rgb(111,216,255)',
                                  width:'40vw',
                                  height:'10vh',
                                  position:'fixed',                                  
                                  top: '50%',
                                  left: '50%',     
                                  borderRadius: '10px',
                                }}
                                >
                                <div className='version-notify-content2'>
                                  <div className='version-notify-text'>
                                    <div> Cloud version no longer valid </div>
                                    <div> Please press (ctrl+F5 or cmd+shift+r) to reload  </div>
                                  </div>
                                </div>
                                </DialogBox>

    return (
      
        <NavBar groupconfig={this.state.groupconfig} username={this.state.username} userInfo={this.state.userInfo}
                    eventNotify={this.navEvent}                   
                    newMessage = {this.state.bNewClipRecieved}                  
                    >
          {versionDialogModeless}
          {versionDialogModal}
          <Authenticator hideDefault={true} onStateChange={(state, data)=>{isRegistrationRequired(state, data,this.authStateChange,this.handleForceLogout)}}>
            <FlatGreetings registerSignOut={this.registerSignOutListener} />
            <FlatSignIn wasForced={this.state.forcedOut} onEvent={this.handleLoginEvents}/>
            <FlatForgotPassword />
            <FlatRequireNewPassword />
            <IdleTimer
              ref={ref => { this.idleTimer = ref }}
              timeout={idleTimeout}
              onIdle={()=>{this.handleForceLogout('idle')}}
              debounce={250}
              startOnMount={false}
              startManually={true}
            />
            <AppWrap
              {...this.state}
              stateCallback = {(data)=>{this.setState(data);}}
              registerNavEventListener={this.registerNavEventListener}
              navEvent={this.navEvent} 
              addVideoCardChange={this.addVideoCardChange}
              messageReceived={this.messageReceived}
            />
            <div className = "topbar_edge3" id="topbar_edge3ID"></div>
            <div className = "sidebar_edge3"></div>
            {/* <div className = "bottombar_edge3"></div> */}
            {
              this.state.username ? <div className='versiontext'></div>:
              <div className='versiontext'>
                  {versionText}
              </div>
            }

          </Authenticator>
        </NavBar>
    )
  }
}
/**
 * Wait for the filter and group config to load
 */
const AppWrap = ({...props}) =>{

  const [possible_filters, setPossibleFilters] = React.useState(props.possibleFilters);
  const [group_config, setGroupConfig] = React.useState(props.groupconfig);

  React.useEffect(()=>{
    // console.log("Update filters:" ,props.possibleFilters);
    applyNameFilters(group_config,props.possibleFilters);
  },[props.possibleFilters]) //
  React.useEffect(()=>{
    // console.log("Group config updated:" ,props.groupconfig)
    applyNameFilters(props.groupconfig,possible_filters);  
    // console.log("Update the config: ",JSON.parse(JSON.stringify(props.groupconfig))) ;
    setGroupConfig(props.groupconfig?{...props.groupconfig}:null);
  },[props.groupconfig]) //

  /**
   * Apply the name aliases to the list of Trifecta Assets
   * @param {*} groupconfig 
   * @param {*} possibleFilters 
   * @returns 
   */
  const applyNameFilters=(groupconfig,possibleFilters)=>{
      //Only apply the name filtering if the possibleFilters and groupC onfig are loaded
      if(!groupconfig.bLoaded || !possibleFilters.bLoaded){
        setPossibleFilters(possibleFilters?{...possibleFilters}:null); //clear the possible filters if a null value is input
        return null;
      }
      // console.log("Both filters set: ")
    //  //Apply a filter to the assets
      let filteredAssets = filterAssetSet(possibleFilters.AssetsTrifecta,{startDate:null, endDate:null},null, groupconfig);      
      if(filteredAssets){
        possibleFilters.AssetsTrifecta = filteredAssets;
      }else{
        console.log("No return",filteredAssets);
      }
      possibleFilters.AssetList = mergeAssetSets(possibleFilters);
      // console.log("Set the filters:",possibleFilters);
      setPossibleFilters(possibleFilters?{...possibleFilters}:null);
  }
  /**
   * Merge the two sets of assets together into a single list (all entries are an object)
   * @param {*} possibleFilters 
   */
  const mergeAssetSets=(possibleFilters)=>{

    // console.log("Merge the asset: ",possibleFilters)
    let assetList = [];                    
    if(possibleFilters.AssetsTrifecta){assetList.push(...possibleFilters.AssetsTrifecta)}
    //Iterate over the Assets (name only and don't allow a duplicate entry)
    for (const asset_ of (possibleFilters.Assets||[])){
      //Check if the assets is already in the list:
      let findReturn =  (assetList||[]).findIndex(elem_=>{return elem_.asset === asset_ || elem_.asset === asset_.asset})
      if(findReturn<0)//Not Found
      {
        //Add to the list:
        assetList.push({ asset:asset_ })
      }
    }//end for loop interations
    (assetList||[]).forEach(asset_=>{ if(asset_.site){  asset_.current_site = asset_.site;  }
    });


    // console.log("merged filters: ",possibleFilters,assetList);
    return assetList;
  }

  const render= ()=>{
    //Return the HTML code to display:
    return (
      <RegisteredLogin
          {...props}
          child={<App 
                  linkDataExtra={props.linkDataExtra}
                  navEvents={props.registerNavEventListener}
                  eventNotify={props.navEvent}
                  messageReceived={props.messageReceived}
                  cardChange={props.addVideoCardChange}
                  mainView={props.mainView} 
                  userInfo={props.userInfo}
                  filter={props.filter}  
                  defaultFilter={props.filter}
                  saving={props.saving}
                  possibleFilters={possible_filters}
                  groupconfig={group_config}
                  authState = {props.authState}
                  username = {props.username}
                  popup = {props.popup}
              />}
      />
        
    );
  };//end of render()

  // console.log("Loaded:" ,group_config,possible_filters);
  try {
    return (group_config.bLoaded && possible_filters.bLoaded )? render() : (<div></div>);  
  } catch (error) {
    return (<div></div>);
  }
  

}; //end of wrapped APP call

/*
* @brief Test if a card change is valid data to send
*/
const validCardChange = (card) => {
  // console.log("Check card: ",card);
  if (card.cardID && (card.delete ||
                      (card.status && !card.infractionType) ||
                      (card.severity && !card.parentID) ||
                      card.comment
                     )
     ) {
       return true;
  }
  if (card.cardID && ((card.infractionTags && card.infractionTags.length > 2)||card.infractionTagsCount )    ) {
    // console.log("Card tags: ",card.infractionTags.length)
    //  console.log("invalid check pass 2")
    return true;
  }

  
  const allKeys = ['cardID', 'tag', 'infractionType', 'notes', 'severity', 'parentID', 'infractionID'];
  for (let i = 0; i < allKeys.length; ++i) {
    if (!card[allKeys[i]] && card[allKeys[i]] !== '') {
      console.log("failed all tags ",allKeys[i])
      return false;
    }
  }
  return true;
};

const AppWithAuth = DragDropContext(HTML5Backend)(AppWithAuthInternal);
export default AppWithAuth;
