import React, {  } from 'react';

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

// import '../AdminTab/AdminUserManagement.css';
import './TrainControl.css';



/*
* @brief 
*/
export const TrainControl = ({ groupconfig,possibleFilters,userInfo }) => {

    //define the callbacks to handle setting the active view
    const [activeView, setView] = React.useState('users');

    //List the buttons to be drawn
    let viewTypes = ['evaluate','train'];
    
    //Fix non available button:
    if(!viewTypes.includes(activeView)){
        setView(viewTypes[0]);
    }
    //Define the returned rendered HTML:
    return (
        // <div className='AdminView'>
        <React.Fragment>
            <Tabs className='viewSelection'
                defaultActiveKey={activeView} unmountOnExit={true} mountOnEnter={true} 
                id='uncontrolled-tab-example' 
                    activeKey={activeView} 
                    onSelect={k => setView(k)}
            >
                {viewTypes.map((type) => {
                    return(  <Tab key={type} eventKey={type} title={type}/> )
                })} 
            </Tabs>
            
            {/* {activeView==='evaluate' && 
                // <AdminUserManagement
                //     groupconfig= {groupconfig}
                //     possibleFilters = {possibleFilters}
                //     userInfo = {userInfo}
                // />
            }
            {activeView==='train' && 
                // <AdminSiteManagement
                //     groupconfig= {groupconfig}
                //     possibleFilters = {possibleFilters}
                //     userInfo = {userInfo}
                // />
            } */}
        </React.Fragment>
        // </div>
    );
};



