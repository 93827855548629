
import React, { Component, PureComponent } from 'react';

import { LiveLister } from './LiveTab.js';
import { VIDEOREVIEW_NOREPORT } from '../Util-access.js';

/*
* @brief Display the overall Analytics view for the website
*/
class LiveView extends Component {
  constructor(props) {
    super(props);
    this.chooseVideo = this.chooseVideo.bind(this);
    this.onNavEvents = this.onNavEvents.bind(this);
    this.videoClicked = this.videoClicked.bind(this);
    this.child = React.createRef();
    this.state = {
      currentVideo: props.video,
      showHighlight:false,
    };
    // console.log("AnalyticsView filter:", this.props.filter);
    // console.log("AnalyticsView props:", this.props);
    this.props.navEvents && this.props.navEvents(this.onNavEvents, "AnalyticsView");
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    this.chooseVideo(newProps.video);
  }
  /*
  * @brief Method called when the page is loaded
  */
  componentDidMount() {
    this.chooseVideo(this.props.video);
    
  } //end compenentDidMount
  /*
  * @brief Used as a callback to set the video we should display, either the VideoLister or the VideoHighlights
  */
  chooseVideo(video) {
    // console.log(video);
    this.setState({currentVideo: video, showHighlight: !!video});
  }

  videoClicked(video) {
    this.props.eventNotify({
      type: 'link',
      data: {
        href: this.props.mainView,
        extra: {
          groupconfig: this.props.groupconfig,
          video: video,
          filter: this.props.filter,
        }
      },
    });
  }
  /*
  * @brief Handle any nav events that happen
  * 
  * This event just resets the video to none chosen, this allows the user to press the 'Analytics'
  * menu item to go back from the VideoHighlights view.
  */
  onNavEvents(e) {
    // console.log("NavEVent: ",e);
    //this.chooseVideo(null); ? why is this here, doesn't seem to be necessary?
  }
  /*
  * @brief Called to generate the visual elements on the page. Items declared inside the return() are displayed.
  */
  render() {
    const filterDisplays = [];
    let  m_videoHighlights = null;
    
    const filter = this.props.filter;

    const currentVideo = this.state.currentVideo;
    
      m_videoHighlights = <div>
          <LiveLister filter={this.props.filter} 
                      videoClicked={this.videoClicked}  
                      groupconfig={this.props.groupconfig} 
                      ws={this.props.ws} 
                      filterDisplays = {filterDisplays} 
                      messageReceived={this.props.messageReceived}
                      cardChange={VIDEOREVIEW_NOREPORT?null:this.props.cardChange}
                      possibleFilters={this.props.possibleFilters}
                      username = {this.props.username}
                      userInfo = {this.props.userInfo}
            /> 
      </div>
    //console.log("Show: ",this.state.showHighlight);
      return (
        
        <div>
            {m_videoHighlights}
        </div>
        
      )
    
    //} //end if current video
  }
}


export { LiveView };
